import React, { useState } from "react";
import { Form, Row, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { SuccessLogIn } from "./success-messages/login-success";
import { isValidatePassword } from "./form-validation/login.validation";
import { EmailField } from "./form-fields/login-field/mail.field";
import { PasswordField } from "./form-fields/login-field/password.field";
import axios from "axios";
export const LogIn = () => {
  const [isSpinner, setIsSpinner] = useState(false);
  const [show, setShow] = useState(false); // Affichage du massage de success

  const [user, setUser] = useState({
    password: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    passErr: "",
    mailErr: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidatePassword(user.password))
      setErrors({ ...errors, passErr: "Le mot de passe est incorrect" });
    else {
      setIsSpinner(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL_BACK}/api/login`,
        data: {
          email: user.email,
          password: user.password,
        },
        withCredentials: true,
        withXSRFToken: true,
      })
        .then((res) => {
          setIsSpinner(false);
          localStorage.setItem('email',res.data.Email)          
          if (res.status == 200) setShow(true)
          if (res.status == 201) window.location.href =  "https://auth.informatiquesenwolof.com/redirect";
        })
        .catch((err) => {
          setIsSpinner(false);
          if (err.response && err.response.data && err.response.data.Message) {
            const errResponse = err.response.data.Message;

            if (errResponse.includes("not found")) {
              setErrors({
                ...errors,
                mailErr: "Ce mail est introuvable",
                passErr: "",
              });
            }
          else if (errResponse.includes("Password")) {
            setErrors({
              ...errors,
              passErr: "Mot de passe incorrect !",
              mailErr: "",
            });
          }
          else if(errResponse.includes("Your account")) 
            window.location.href=`${process.env.REACT_APP_API_URL}/failed-login`
        } 
        else alert("Une erreur est survenue !");
        });
    }
  };

  return (
    <>
      {!isSpinner && !show && (
        <Form className="form-login" onSubmit={handleSubmit}>
          <h3 style={{ textAlign: "center" }}>Connectez-vous !</h3>
          <EmailField
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            mailErr={errors.mailErr}
          />
          <PasswordField
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            passErr={errors.passErr}
          />
          <Row>
            <Button id="submit" type="submit">
              Connexion
            </Button>
          </Row>
          <div className="other-link">
            <a className="a" 
            onClick={()=>window.location.href = `${process.env.REACT_APP_API_URL}`}>Créez mon compte</a>
            <a className="a" 
            onClick={()=>window.location.href = `${process.env.REACT_APP_API_URL}/reset-password`}>Mot de passe oublié ?</a>
          </div>
          <div className="other-link-2">
            <a className="a">Politique de confidentialité</a>
            <a className="a"></a>
          </div>
        </Form>
      )}
      {isSpinner && (
        <>
          <div className="spinner">
            <h2>Connexion en cours...</h2>
            <Spinner animation="border" role="status" />
          </div>
        </>
      )}
      <SuccessLogIn show={show} iShow={() => window.location.href = `${process.env.REACT_APP_API_URL_HOME}`} />
    </>
  );
};
